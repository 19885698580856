export const COUNTRIES = [
  { label: "Česká republika", value: "cz" },
  { label: "Slovenská republika", value: "sk" },
  { label: "Österreich", value: "at" },
  { label: "România", value: "ro" },
  { label: "Hungary", value: "hu" },
  { label: "Polska", value: "pl" },
  { label: "Croatia", value: "hr" },
  { label: "Lithuania", value: "lt" },
];
