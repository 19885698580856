import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;

export const createUser = (user) =>
  errorHandler(async () => {
    const data = await api.post("/user", {
      email: user.email.replace(" ", ""),
      password: user.password.replace(" ", ""),
      role: user.role,
      active: true,
    });
    return { data: data.data };
  });

export const getUsers = () =>
  errorHandler(async () => {
    const data = await api.get("/user");
    return { data: data.data };
  });

export const deleteUser = (userId) =>
  errorHandler(async () => {
    const data = await api.delete("/user/" + userId);
    return { data: data.data };
  });

export const updateUser = (_id, user) =>
  errorHandler(async () => {
    const data = await api.patch(`/user/${_id}`, {
      email: user.email.replace(" ", ""),
      password: user.password.replace(" ", ""),
      role: user.role,
      active: true,
    });
    return { data: data.data };
  });

export const getOneUser = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`user/${_id}`);
    return { data: data.data };
  });

export const login = (email, password) =>
  errorHandler(async () => {
    const data = await api.post("/user/login", {
      email: email,
      password: password,
    });
    tokenManager.setToken(data.data.token);
    tokenManager.setRefreshToken(data.data.refreshToken);
    return { data: data.data.token };
  });
