import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

import styled from "styled-components";
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser,
} from "../API/repositories/user";
import Confirm from "../common/components/Confirm";
import Input from "../common/components/Input";
import Loading from "../common/components/Loading";
import PopUp from "../common/components/PopUp";
import SelectInput from "../common/components/SelectInput";
import { ROLES } from "../common/constants/roles";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { useRequestsContext } from "../common/hooks/requestHook";
import Table from "../common/components/Table";

const Wrapper = styled.div`
  padding: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const FLexRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const CreateUser = ({ setShowCreateUser }) => {
  const [slectedRole, setSelectedRole] = useState(ROLES[1]);
  const emailRef = useRef();
  const passwordRef = useRef();

  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object();

    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;
    payload.role = slectedRole.value;

    const response = await makeRequest(createUser.bind(null, payload));
    if (response) {
      setShowCreateUser(() => false);
    }
  };

  return (
    <>
      {hasUnfilledRequest(createUser) && <Loading />}
      <PopUp setShow={setShowCreateUser}>
        <h4>Create User</h4>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Input
            inputRef={emailRef}
            name="Email"
            type="email"
            required
            inputWidth={250}
          />
          <Input
            inputRef={passwordRef}
            name="Password"
            type="password"
            required
            inputWidth={250}
          />
          <SelectInput
            name="Role"
            options={ROLES}
            selected={slectedRole}
            setSelected={setSelectedRole}
            width="140"
          />
          <FLexRight>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </FLexRight>
        </form>
      </PopUp>
    </>
  );
};

const EditUser = ({ setShowEditUser, user }) => {
  const [slectedRole, setSelectedRole] = useState(
    ROLES.find((role) => role.value === user.role)
  );
  const emailRef = useRef();
  const passwordRef = useRef();

  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object();

    payload.email = emailRef.current ? emailRef.current.value : user.email;
    payload.password = passwordRef.current ? passwordRef.current.value : null;
    payload.role = slectedRole.value;

    const response = await makeRequest(
      updateUser.bind(null, user._id, payload)
    );
    if (response.data) {
      setShowEditUser(() => false);
    }
  };
  return (
    <>
      {hasUnfilledRequest(updateUser) && <Loading />}
      <PopUp setShow={setShowEditUser}>
        <h4>Create User</h4>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Input
            inputRef={emailRef}
            name="Email"
            type="email"
            required
            value={user.email}
            inputWidth={250}
          />
          <Input
            inputRef={passwordRef}
            name="Password"
            type="password"
            inputWidth={250}
          />
          <SelectInput
            name="Role"
            options={ROLES}
            selected={slectedRole}
            setSelected={setSelectedRole}
            width="140"
          />
          <FLexRight>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </FLexRight>
        </form>
      </PopUp>
    </>
  );
};

const Users = () => {
  const [users, setUsers] = useState();
  const [showCreateUser, setShowCreateUser] = useState();
  const [editUserData, setEditUserData] = useState();
  const [showEditUser, setShowEditUser] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState();
  const [deleteUserData, setDeleteUserData] = useState();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getUsers.bind());

    if (response.data) {
      setUsers(() => response.data);
    }
  };

  const handleEditUser = (e, user) => {
    e.preventDefault();

    setEditUserData(() => user);
    setShowEditUser(() => true);
  };

  const handleDeleteDecision = (e, user) => {
    e.preventDefault();
    setDeleteUserData(() => user);
    setShowDeleteConfirm(() => true);
  };

  const handleDeleteUser = async (e) => {
    e.preventDefault();

    const response = await makeRequest(
      deleteUser.bind(null, deleteUserData._id)
    );

    if (response.data) {
      setDeleteUserData(() => null);
      setShowDeleteConfirm(() => false);
      await loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, [showCreateUser, showEditUser]);

  const headers = ["Email", "Role", "Created at", "Options"];
  const raws = useMemo(
    () =>
      users?.map((user) => (
        <tr key={user._id}>
          <td>{user.email}</td>
          <td>{user.role}</td>
          <td>{moment(user.created_at).format("YYYY-MM-DD HH:mm")}</td>
          <td>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <button
                className="btn btn-secondary"
                onClick={(e) => handleEditUser(e, user)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => handleDeleteDecision(e, user)}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      )),
    [users]
  );

  return (
    <Wrapper>
      {(hasUnfilledRequest(getUsers) || hasUnfilledRequest(deleteUser)) && (
        <Loading />
      )}
      <Flex>
        <button
          className="btn btn-warning"
          onClick={() => setShowCreateUser(true)}
        >
          Create user
        </button>
      </Flex>
      {showCreateUser && <CreateUser setShowCreateUser={setShowCreateUser} />}
      {showEditUser && (
        <EditUser setShowEditUser={setShowEditUser} user={editUserData} />
      )}
      {showDeleteConfirm && (
        <Confirm
          text={"Do you want to delete the user?"}
          onConfirm={handleDeleteUser}
          onDeceline={setShowDeleteConfirm}
        />
      )}
      <Flex>
        {users && (
          <Table className="styled-table" raws={raws} headers={headers} />
        )}
      </Flex>
    </Wrapper>
  );
};

export default withRequestProvider(Users);
