import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getMarkets } from "../API/repositories/market";
import { getProducts } from "../API/repositories/product";
import EditIcon from "../common/components/EditIcon";
import Loading from "../common/components/Loading";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { useRequestsContext } from "../common/hooks/requestHook";
import { MarketForm } from "../components/markets/MarketForm";
import { getMatched, makeVisible } from "../common/functions/settingsHelpers";
import Table from "../common/components/Table";
import { getCurrencies } from "../API/repositories/currency";

const Wrapper = styled.div`
  padding: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const Markets = () => {
  const [editMarketData, setEditMarketData] = useState();
  const [showEditMarket, setShowEditMarket] = useState();
  const [markets, setMarkets] = useState();
  const [products, setProducts] = useState([]);
  const [showCreateMarket, setShowCreateMarket] = useState();
  const [currencies, setCurrencies] = useState();
  const navigate = useNavigate();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadData = async () => {
    const marketsResponse = await makeRequest(getMarkets);

    if (marketsResponse?.data) {
      setMarkets(() => marketsResponse.data);
    }

    const productsResponse = await makeRequest(getProducts);

    if (productsResponse?.data) {
      setProducts(() => productsResponse.data);
    }

    const currenciesResponse = await makeRequest(getCurrencies);

    if (currenciesResponse?.data) {
      setCurrencies(() => currenciesResponse.data);
    }
  };

  const handleEditMarket = (e, market) => {
    e.preventDefault();

    setEditMarketData(() => market);
    setShowEditMarket(() => true);
  };

  useEffect(() => {
    loadData();
  }, []);

  const headers = ["No", "Name", "Short", "Products", "Created at", "Options"];
  const raws = useMemo(
    () =>
      markets?.map((market, i) => (
        <tr key={market._id}>
          <td>{i + 1}</td>
          <td>{market.name}</td>
          <td>{market.short}</td>
          <td
            style={{
              maxWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {makeVisible(
              getMatched(
                [...market.default_products, ...market.products],
                products
              )
            )}
          </td>
          <td>{moment(market.created_at).format("YYYY-MM-DD HH:mm")}</td>
          <td>
            <EditIcon handleEdit={(e) => handleEditMarket(e, market)} />
          </td>
        </tr>
      )),
    [markets, products]
  );

  return (
    <Wrapper>
      {hasUnfilledRequest(getMarkets) && <Loading />}
      <Flex>
        <button
          className="btn btn-warning"
          onClick={() => navigate("/settings")}
        >
          Go back
        </button>

        <button
          className="btn btn-warning"
          onClick={() => setShowCreateMarket(true)}
        >
          Create Market
        </button>
      </Flex>
      {showCreateMarket && (
        <MarketForm
          currencies={currencies}
          loadData={loadData}
          products={products}
          setShowMarketForm={setShowCreateMarket}
        />
      )}
      {showEditMarket && (
        <MarketForm
          currencies={currencies}
          loadData={loadData}
          products={products}
          setShowMarketForm={setShowEditMarket}
          market={editMarketData}
        />
      )}
      <Flex>
        {markets && (
          <Table className="styled-table" headers={headers} raws={raws} />
        )}
      </Flex>
    </Wrapper>
  );
};

export default withRequestProvider(Markets);
