import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;

export const createCurrency = (currency) => {
  return errorHandler(async () => {
    const data = await api.post("/currency", currency);

    return { data: data.data };
  });
};

export const getCurrencies = () => {
  return errorHandler(async () => {
    const data = await api.get("/currency");

    return { data: data.data };
  });
};

export const getOneCurrency = (_id) => {
  return errorHandler(async () => {
    const data = await api.get(`currency/${_id}`);

    return { data: data.data };
  });
};

export const updateCurrency = (_id, currency) => {
  return errorHandler(async () => {
    const data = await api.patch("currency", currency);

    return { data: data.data };
  });
};
