import React, { useRef, useState } from "react";
import CSVReader from "react-csv-reader";
import { Colors } from "../colors";

const CSVFromFile = ({ setFileData }) => {
  return (
    <div>
      <CSVReader
        inputStyle={{ color: Colors.darkBlue }}
        onFileLoaded={(data, fileInfo, originalFile) => setFileData(data)}
      />
    </div>
  );
};

export default CSVFromFile;
