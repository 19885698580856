import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;

export const uploadReport = (payload) =>
  errorHandler(async () => {
    const data = await api.patch(`crm/change_to_delivered_by_report/`, {
      payload,
    });
    return { data: data.data };
  });
