import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { uploadReport } from "../API/repositories/report";
import CSVFromFile from "../common/components/CSVFromFile";
import Input from "../common/components/Input";
import Loading from "../common/components/Loading";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { useRequestsContext } from "../common/hooks/requestHook";
import Table from '../common/components/Table';
import { Colors } from "../common/colors";

const Wrapper = styled.div`
  padding: 20px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const Message = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  font-size: 24px;
`

const COLOR = {
  success: Colors.green,
  error: Colors.red,
}

const Reports = () => {
  const raportForDayRef = useRef();
  const [fileData, setFileData] = useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const [showError, setShowError] = useState();
  const [uploadState, setUploadState] = useState();

  const createObjectFrom = () => {
    let result = [];

    fileData?.forEach((data, i) => {
      if (i === 0) return;

      const element = Object();

      fileData[0].forEach((header, i) => {
        element[header] = data[i];
      });

      result.push(element);
    });

    return result;
  };

  const handleUploadCsv = async () => {
    if (!raportForDayRef.current?.value || createObjectFrom().length === 0) {
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
      }, 2000);

      return;
    }

    const allReports = createObjectFrom();

    for (let i = 0; i < allReports.length; i += 200) {
      const payload = {};

      payload.report = createObjectFrom().slice(i, i + 200);
      payload.date = raportForDayRef.current.value;

      const response = await makeRequest(uploadReport.bind(null, payload));
      if (!response.data) {
        setUploadState(() => 'error');
        setTimeout(() => { setUploadState(() => null) }, 2000);

        return;
      }
    }

    
    setUploadState(() => 'success');

    setTimeout(() => { 
      setUploadState(() => null);
      setFileData(() => null);
      window.location.reload();
    }, 2000);

  };

  const headers = fileData
  ? ['no. ', ...fileData[0]?.map((headers, i) => {
    if (i === 10) {
      return;
    }
    
    return headers;
  })]
  : []

  const raws = useMemo(() => 
    (createObjectFrom().map((row, i) => {
      return (
        <tr key={`${row['Tracking number']}`}>
          <td>{i + 1}</td>
          {Object.values(row).map((element) => {
            return (
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {element ? element : "------"}
              </td>
            );
          })}
        </tr>
      );
    }))
  , [createObjectFrom])

  return (
    <Wrapper>
      {hasUnfilledRequest(uploadReport) && <Loading />}
      <Flex>
        {showError && (
          <div style={{ color: "red" }}>PLEASE PICK DATE OR UPLOAD REPORT</div>
        )}
      </Flex>

      {uploadState && (
        <FlexCenter>
          <Message style={{ backgroundColor: COLOR[uploadState]}}>
            {uploadState}
          </Message>
        </FlexCenter>
      )}

      <Flex>
        <Input name="Raport for day" type="date" inputRef={raportForDayRef} />
        <CSVFromFile setFileData={setFileData} />
        <button className="btn btn-warning" onClick={(e) => handleUploadCsv(e)}>
          Upload new file
        </button>
      </Flex>
      {fileData && (
          <FlexCenter>
            <Table style={{ marginTop: "50px" }} className="styled-table" raws={raws} headers={headers} />
          </FlexCenter>
      )}
    </Wrapper>
  );
};

export default withRequestProvider(Reports);
