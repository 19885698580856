import React, { useRef, useState } from "react";
import styled from "styled-components";
import { createProduct, updateProduct } from "../../API/repositories/product";
import SelectInput from "../../common/components/SelectInput";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import PopUp from "../../common/components/PopUp";
import { useRequestsContext } from "../../common/hooks/requestHook";
import {
  getMatched,
  convertToOptions,
} from "../../common/functions/settingsHelpers";
import { Colors } from "../../common/constants/colors";
import { getFileExtension } from "../../common/functions/getFileExtension";
import { getBase64 } from "../../common/functions/getBase64";
import { handleOpenFileByBase64 } from "../../common/functions/handleOpenFileByBase64";

const FLexRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 12px;
  margin: 0 0 -20px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
`;

const Wrapper = styled.div`
  padding: 36px 0;
  display: flex;
  gap: 20px;
`;

const VariantsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 18px;
  margin-top: -7px;
`;

const Title = styled.h4`
  color: ${Colors.darkBlue};
`;

const SubTitle = styled.h5`
  color: ${Colors.darkBlue};
  margin-left: 65px;
`;

const VariantItem = styled.div`
  cursor: pointer;
  background-color: ${Colors.green};
  padding: 8px;
  color: white;
  text-align: center;
  border-radius: 10px;
`;

const ShippingLabelWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  gap: 8px;
  color: ${Colors.darkBlue};
`;

export const ProductForm = ({
  setShowProductForm,
  product,
  posts,
  loadData,
}) => {
  const [selectedPosts, setSelectedPosts] = useState(
    product ? convertToOptions(getMatched(product.posts, posts)) : []
  );
  const [selectedDefaultPosts, setSelectedDefaultPosts] = useState(
    product ? convertToOptions(getMatched(product.default_posts, posts)) : []
  );
  const [isAddVariantOpen, setIsAddVariantOpen] = useState(false);
  const [variants, setVariants] = useState(
    product ? [...product.variants] : []
  );

  const fileRef = useRef();
  const nameRef = useRef();
  const shortRef = useRef();
  const minPriceRef = useRef();
  const variantNameRef = useRef();
  const variantShortRef = useRef();
  const weightRef = useRef();

  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();
  const addVariant = (newVariant) => {
    setVariants((prev) => [...prev, newVariant]);
  };

  const deleteVariant = (variantToDelete) => {
    const filteredVariants = [...variants].filter(
      (variant) => variant.short !== variantToDelete.short
    );

    setVariants(() => filteredVariants);
  };

  const handleVariantSubmit = (e) => {
    e.preventDefault();

    const newVariant = {
      name: variantNameRef.current.value,
      short: variantShortRef.current.value,
    };

    addVariant(newVariant);
    setIsAddVariantOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object();

    const file = fileRef.current?.files[0];

    if (file && getFileExtension(fileRef.current?.files[0].name) !== "pdf")
      return;

    const fileData = file
      ? await getBase64(fileRef.current?.files[0])
      : product?.file_data;

    if (!fileData) {
      return;
    }

    payload.name = nameRef.current.value;
    payload.short = shortRef.current.value;
    payload.posts = selectedPosts.map((post) => post.value);
    payload.default_posts = selectedDefaultPosts.map((post) => post.value);
    payload.min_price = minPriceRef.current.value;
    payload.variants = variants;
    payload.file_data = fileData;
    payload.weight = weightRef.current.value;

    const response = product
      ? await makeRequest(updateProduct.bind(null, product._id, payload))
      : await makeRequest(createProduct.bind(null, payload));

    if (response?.data) {
      setShowProductForm(() => false);
      loadData();
    }
  };

  return (
    <>
      {hasUnfilledRequest(updateProduct, createProduct) && <Loading />}
      <PopUp setShow={setShowProductForm}>
        <Title>{product ? "Edit Product" : "Create product"}</Title>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Flex>
            <Input
              color={Colors.darkBlue}
              name="Name"
              value={product?.name}
              inputRef={nameRef}
              required
              inputWidth="250"
            />
            <Input
              color={Colors.darkBlue}
              name="Short"
              value={product?.short}
              inputRef={shortRef}
              required
              inputWidth="250"
            />
          </Flex>
          <Flex>
            <Input
              color={Colors.darkBlue}
              name="Min price"
              value={product?.min_price}
              inputRef={minPriceRef}
              required
              inputWidth="250"
            />
            <div>
              <Input
                color={Colors.darkBlue}
                inputWidth={250}
                width={140}
                name="Shipping label"
                type="file"
                inputRef={fileRef}
                accept="application/pdf"
              />
              {product?.file_data && (
                <ShippingLabelWrapper
                  onClick={() => handleOpenFileByBase64(product.file_data)}
                >
                  download shipping label
                  <i className="fa-solid fa-file-pdf" />
                </ShippingLabelWrapper>
              )}
            </div>
          </Flex>
          <Flex>
            <SelectInput
              name="Couriers"
              color={Colors.darkBlue}
              selected={selectedPosts}
              options={convertToOptions(posts)}
              setSelected={setSelectedPosts}
              required
              multiple
              selectWidth={250}
              width={140}
            />
            <Input
              color={Colors.darkBlue}
              inputWidth={250}
              width={250}
              name="Weight"
              type="number"
              inputRef={weightRef}
              required
              value={product?.weight}
            />
          </Flex>
          <Flex>
            <SelectInput
              color={Colors.darkBlue}
              name="Default couriers"
              selected={selectedDefaultPosts}
              options={convertToOptions(posts)}
              setSelected={setSelectedDefaultPosts}
              required
              multiple
              selectWidth="250"
              width="140"
            />
          </Flex>
          <Wrapper>
            <SubTitle>Varaiants:</SubTitle>
            <VariantsWrapper>
              {variants?.map((variant) => (
                <VariantItem
                  onClick={() => deleteVariant(variant)}
                  key={variant.short}
                >
                  <p>
                    {variant.name} - {variant.short}
                  </p>
                </VariantItem>
              ))}
            </VariantsWrapper>
          </Wrapper>
          <FLexRight>
            <button
              onClick={() => setIsAddVariantOpen(true)}
              type="button"
              className="btn btn-warning"
            >
              Add variant
            </button>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </FLexRight>
        </form>
      </PopUp>
      {isAddVariantOpen && (
        <PopUp setShow={setIsAddVariantOpen}>
          <h4>Add variant</h4>
          <form onSubmit={(e) => handleVariantSubmit(e)}>
            <Input
              name="name"
              required
              inputWidth="250"
              inputRef={variantNameRef}
            />
            <Input
              name="short"
              required
              inputWidth="250"
              inputRef={variantShortRef}
            />
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </form>
        </PopUp>
      )}
    </>
  );
};
