import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../colors";
import { useLocation } from "react-router-dom";
import userManager from "../../API/userManager";
import api from "../../API/api";

const Wrapper = styled.div`
  height: 60px;
  background-color: ${Colors.darkBlue};
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  ul {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    gap: 20px;
    color: white;
    font-weight: bold;

    li {
      cursor: pointer;
      list-style-type: none;
      text-decoration: none;
    }
  }
`;

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = userManager.getUser();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    userManager.removeUser();
    api.tokenManager.removeToken();
    navigate("/login");
  };

  return (
    <Wrapper>
      <ul>
        <li
          className="animation-scale"
          onClick={() => handleNavigation("/reports")}
          style={{
            transform:
              pathname.split("/")[1] === "reports" ? "scale(1.1)" : "scale(1)",
            textDecoration:
              pathname.split("/")[1] === "reports" ? "underline" : null,
          }}
        >
          Reports
        </li>
        <li
          style={{
            transform:
              pathname.split("/")[1] === "jobs" ? "scale(1.1)" : "scale(1)",
            textDecoration:
              pathname.split("/")[1] === "jobs" ? "underline" : null,
          }}
          className="animation-scale"
          onClick={() => handleNavigation("/jobs")}
        >
          Jobs
        </li>
        {user?.role === "ADMIN" && (
          <li
            className="animation-scale"
            style={{
              transform:
                pathname.split("/")[1] === "users" ? "scale(1.1)" : "scale(1)",
              textDecoration:
                pathname.split("/")[1] === "users" ? "underline" : null,
            }}
            onClick={() => handleNavigation("/users")}
          >
            Users
          </li>
        )}
        <li
          style={{
            transform:
              pathname.split("/")[1] === "orders" ? "scale(1.1)" : "scale(1)",
            textDecoration:
              pathname.split("/")[1] === "orders" ? "underline" : null,
          }}
          className="animation-scale"
          onClick={() => handleNavigation("/orders")}
        >
          Orders
        </li>
        <li
          style={{
            transform:
              pathname.split("/")[1] === "books" ? "scale(1.1)" : "scale(1)",
            textDecoration:
              pathname.split("/")[1] === "books" ? "underline" : null,
          }}
          className="animation-scale"
          onClick={() => handleNavigation("/books")}
        >
          Books
        </li>
        {user?.role === "ADMIN" && (
          <li
            className="animation-scale"
            style={{
              transform:
                pathname.split("/")[1] === "settings" ? "scale(1.1)" : "scale(1)",
              textDecoration:
                pathname.split("/")[1] === "settings" ? "underline" : null,
            }}
            onClick={() => handleNavigation("/settings")}
          >
            Settings
          </li>
        )}
      </ul>
      <ul>
        <li style={{ cursor: "auto" }}>{user?.email}</li>
        <li className="animation-scale" onClick={(e) => handleLogout(e)}>
          Logout
        </li>
      </ul>
    </Wrapper>
  );
};

export default Navbar;
