export const SETTINGS_CONFIG = [
  {
    name: "Markets",
    navigation: "markets",
    icon: "fa-solid fa-map-location-dot",
  },
  {
    name: "Products",
    navigation: "products",
    icon: "fa-solid fa-box",
  },
  {
    name: "Couriers",
    navigation: "posts",
    icon: "fa-solid fa-envelopes-bulk",
  },
  {
    name: "Flags",
    navigation: "flags",
    icon: "fa-solid fa-flag",
  },
  {
    name: "Currencies",
    navigation: "currencies",
    icon: "fa-solid fa-wallet",
  },
];
