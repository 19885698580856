import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../common/colors";
import { SETTINGS_CONFIG } from "../common/constants/settingsConfig";

const Wrapper = styled.div`
  padding: 10px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 20px;
  gap: 50px;
  grid-template-columns: repeat(3, 1fr);
`;

const ListItem = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  min-height: 150px;
  min-width: 250px;
  border: 1px solid lightgrey;
  padding: 8px;
  cursor: pointer;
  transition: transform 0.3s;
  border-radius: 8px;
  border: 2px solid ${Colors.darkBlue};
  color: ${Colors.darkBlue};
  font-size: 32px;
  font-weight: bold;
  :hover {
    transform: scale(1.1);
    color: ${Colors.red};
  }
`;

const Settings = () => {
  return (
    <Wrapper>
      <List>
        {SETTINGS_CONFIG.map((configItem) => (
          <ListItem key={configItem.name} to={configItem.navigation}>
            {configItem.name}
            <i className={configItem.icon} />
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default Settings;
