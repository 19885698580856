import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Input from "../common/components/Input";
import SelectInput from "../common/components/SelectInput";
import momentBusinesDays from "moment-business-days";
import { useRequestsContext } from "../common/hooks/requestHook";
import {
  getOrdersForMarket,
  getOrdersToSend,
  verifyOrder,
} from "../API/repositories/order";
import withRequestProvider from "../common/hocs/withRequestProvider";
import moment from "moment/moment";
import { Colors } from "../common/colors";
import {
  getBooksFromCzechLogistic,
  sendOrders,
} from "../API/repositories/czechlogistic";
import SentView from "../components/orders/SentView";
import Edit from "../components/orders/Edit";
import Loading from "../common/components/Loading";
import { getMarketsConfig } from "../API/repositories/market";
import Table from "../common/components/Table";
import userManager from "../API/userManager";

const Wrapper = styled.div`
  padding: 20px;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-left: 14px;
  color: ${Colors.darkBlue};
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const OrdersWrapper = styled.div`
  margin: 20px;
`;

const CouriersInfo = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const PARCELS_STEP = 35;

const handleCountDays = (howMany) => {
  return momentBusinesDays().businessAdd(howMany).format("YYYY-MM-DD");
};

const Orders = () => {
  const dateRef = useRef();
  const [bookNumbers, setBookNumbers] = useState();
  const [selectedBook, setSelectedBook] = useState();
  const [marketProducts, setMarketProducts] = useState([]);
  const [config, setConfig] = useState([]);
  let defaultShippingDate = handleCountDays(0);

  const [editData, setEditData] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const [sentResultData, setSentResultData] = useState(null);
  const [sentResultShow, setSentResultShow] = useState(false);

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const [selectedParcels, setSelectedParcels] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [postOptions, setPostOptins] = useState(null);
  const [data, setData] = useState();
  const [isLoadingParcels, setIsLoadingParcels] = useState(false);

  const onChangeSelected = (e, id, state) => {
    if (state) setSelectedParcels((prev) => [...prev, id]);
    else
      setSelectedParcels((prev) => [...prev.filter((parcel) => parcel !== id)]);
  };

  const handleSearch = async (e) => {
    if (e) e.preventDefault();

    await getProductsForMarket();

    const selectedCountryOption = config.find(
      (info) => info.country.value === selectedCountry.value
    );

    const payload = Object();

    payload.countryCode = selectedCountry?.value;
    payload.productCode = selectedProduct?.value;
    payload.curier_lms_id = selectedPost?.value;
    payload.toUtc = dateRef.current?.value
      ? dateRef.current?.value
      : handleCountDays(selectedCountryOption?.addDays);

    payload.toUtc = moment(payload.toUtc).toISOString();

    const response = await makeRequest(getOrdersToSend.bind(null, payload));
    const books = await makeRequest(
      getBooksFromCzechLogistic.bind(
        null,
        selectedCountry?.value,
        selectedPost?._id
      )
    );

    if (response.data) {
      const filteredSelectedParcels = selectedParcels.filter((parcel) =>
        response.data.some((order) => order._id === parcel)
      );

      setSelectedParcels(() => filteredSelectedParcels);

      setData(
        response.data?.map((order) => {
          const price = order.order_items.reduce(
            (acc, item) => item.price + acc,
            0
          );
          const variants = order.order_items
            .map((item) => item.product_variant)
            .join(", ");

          return { ...order, price, variants };
        })
      );

      if (books.data?.items) {
        setBookNumbers(() =>
          books.data.items?.map((book) => {
            return { label: book.nr, value: book.nr };
          })
        );
        setSelectedBook(() => {
          return {
            label: books.data.items[0]?.nr,
            value: books.data.items[0]?.nr,
          };
        });
      }
    }
  };

  const handleSendParcels = async (e) => {
    e.preventDefault();
    if (!selectedParcels?.length) return;

    setIsLoadingParcels(() => true);

    await getProductsForMarket();

    const totalSent = {
      sentParcels: [],
      unSentParcels: [],
    };

    for (let i = 0; i < selectedParcels.length; i += PARCELS_STEP) {
      const payload = Object();

      payload.parcels = selectedParcels.slice(i, i + PARCELS_STEP);
      payload.book_number = selectedBook ? selectedBook.value : null;

      payload.country = selectedCountry.value;
      payload.product_id = selectedProduct.product_id;
      payload.default_product = selectedCountry.default_products.some(
        (product) => selectedProduct.value === product.short
      );
      payload.post_id = selectedPost._id;
      payload.lms_sender = userManager.getUser().email;

      const response = await makeRequest(sendOrders.bind(null, payload));

      totalSent.sentParcels.push(...(response?.data.sentParcels || []));
      totalSent.unSentParcels.push(...(response?.data.unSentParcels || []));
    }

    setSentResultData(() => totalSent);
    setSentResultShow(() => true);
    setIsLoadingParcels(() => false);
    setSelectedParcels(() => []);
    setData(() => null);
  };

  const handleOpenGSEARCH = (e, order) => {
    e.preventDefault();
    const gSearchLink = `https://www.google.com/search?q=${order.full_name.replaceAll(
      " ",
      "+"
    )}`;
    window.open(gSearchLink, "_blank").focus();
  };

  const handleOpenGMAP = (e, order) => {
    e.preventDefault();
    const gMapsLink = `https://www.google.com/maps?q=${order.country},${order.city},${order.street},${order.postal_code}`;
    window.open(gMapsLink, "_blank").focus();
  };

  const handleSeeInCrm = (e, _id) => {
    e.preventDefault();
    const crmLink = `https://crm.europa-edu.eu/dashboard/order/` + _id;
    window.open(crmLink, "_blank").focus();
  };

  const handleSeeInCrmContact = (e, _id) => {
    e.preventDefault();
    const crmLink = `https://crm.europa-edu.eu/dashboard/contact/` + _id;
    window.open(crmLink, "_blank").focus();
  };

  const handleSelectCountry = (selected) => {
    const selectedCountryOption = config.find(
      (info) => info.country.value === selected.value
    );

    setSelectedCountry(() => selected);
    setProductOptions(() => selectedCountryOption.products);
    setSelectedProduct(() => selectedCountryOption.products[0]);
    setSelectedPost(() => selectedCountryOption.products[0].posts[0]);
    setPostOptins(() => selectedCountryOption.products[0].posts);

    dateRef.current.value = handleCountDays(selectedCountryOption?.addDays);
  };

  const handleSelectProduct = (selected) => {
    setSelectedProduct(() => selected);
    setSelectedPost(() => selected.posts[0]);
    setPostOptins(() => selected.posts);
  };

  const handleVerify = async (e, order) => {
    e.preventDefault();

    const response = await makeRequest(verifyOrder.bind(null, order));

    if (response.data) {
      await handleSearch();
    }
  };

  const handleMarkAllAsChecked = (e) => {
    e.preventDefault();
    setSelectedParcels(() => data?.map((element) => element._id));
  };

  const handleMarkAllAsUnChecked = (e) => {
    e.preventDefault();
    setSelectedParcels(() => []);
  };

  const countPrice = (orderItems) => {
    return orderItems.reduce((prev, next) => prev + next.price, 0);
  };

  const handleSuspiciousPrice = (data) => {
    const price = countPrice(data.order_items);
    const minPrice = config
      .find((market) => market.country.value === data.country)
      ?.products.find(
        (product) => product.value === selectedProduct.value
      )?.minPrice;

    if (price < minPrice) {
      return true;
    }

    return false;
  };

  const handleEditOrderAndItems = (e, id, currency) => {
    e.preventDefault();

    setEditData(() => ({ id, currency }));
    setEditShow(() => true);
  };

  const getFormattedConfig = async () => {
    const configData = await makeRequest(getMarketsConfig);
    const newConfig = configData.data?.map((market) => ({
      country: {
        label: market.name,
        value: market.short,
        default_products: market._default_products,
      },
      products: [...market._default_products, ...market._products].map(
        (product) => ({
          label: product?.name,
          value: product.short,
          minPrice: product.min_price,
          product_id: product._id,
          posts: [...product._default_posts, ...product._post].map((post) => ({
            label: post?.name,
            value: post?._id,
            _id: post._id,
            curier_name: post.curier_name,
          })),
        })
      ),
      addDays: market.addDays,
    }));

    if (newConfig) {
      setSelectedCountry(() => newConfig[0].country);
      setSelectedProduct(() => newConfig[0]?.products[0]);
      setSelectedPost(() => newConfig[0]?.products[0].posts[0]);
      setProductOptions(() => newConfig[0]?.products);
      setPostOptins(() => newConfig[0]?.products[0].posts);
      dateRef.current.value = handleCountDays(newConfig[0]?.addDays);
      setConfig(() => newConfig);
    }
  };

  const getProductsForMarket = async () => {
    const selectedCountryOption = config.find(
      (info) => info.country.value === selectedCountry.value
    );

    const payload = Object();
    payload.countryCode = selectedCountry?.value;
    payload.toUtc = dateRef.current?.value
      ? dateRef.current?.value
      : handleCountDays(selectedCountryOption.addDays);
    payload.shorts = productOptions?.map((product) => product.value);
    payload.toUtc = moment(payload.toUtc).toISOString();

    const response = await makeRequest(getOrdersForMarket.bind(null, payload));

    setMarketProducts(() => response.data);
  };

  const getOrderItems = (order) => {
    return order.shipping?.order_items?.length
      ? order.shipping?.order_items
      : order.order_items;
  };

  useEffect(() => {
    handleSearch();
  }, [editShow]);

  useEffect(() => {
    getFormattedConfig();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getProductsForMarket();
    }
  }, [selectedCountry]);

  const headers = [
    "Country",
    "City",
    "Postal code",
    "Street",
    "Full name",
    "Phone",
    "Email",
    "Delivery date",
    "Price",
    "Variants",
    "Options",
  ];

  const raws = useMemo(
    () =>
      data?.sort((orderA, orderB) => parseInt(orderB.postal_code?.replace(/\D/g, '')) - parseInt(orderA.postal_code?.replace(/\D/g, '')))?.map((order) => (
        <tr key={order._id}>
          <td>{order.country}</td>
          <td>{order.city}</td>
          <td>{order.postal_code}</td>
          <td>{order.street}</td>
          <td>
            {order.additional_info && order.additional_info.length > 0
              ? order.full_name + ", " + order.additional_info
              : order.full_name}
          </td>
          <td>
            {(order.phone?.courier || order.phone?.default)
              ?.match(/.{1,3}/g)
              .join("-")}
          </td>
          <td>{order.email}</td>
          <td>{moment(order.suggested_delivery_date).format("YYYY-MM-DD")}</td>
          <td
            style={{
              background: handleSuspiciousPrice(order)
                ? Colors.red
                : Colors.green,
            }}
          >
            {countPrice(getOrderItems(order))}
            {" " + (order.shipping?.currency || order.currency)}
          </td>
          <td>
            {getOrderItems(order).map((item, i) => (
              <div key={i}>
                {item.product_variant} {item.price}{" "}
                {order.shipping?.currency || order.currency}
              </div>
            ))}
          </td>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                checked={selectedParcels.includes(order._id)}
                type="checkbox"
                onClick={(e) => {
                  onChangeSelected(e, order._id, e.target.checked);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  alignSelf: "center",
                }}
              />
              <div
                style={{
                  minHeight: "120px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  width: "220px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa fa-map animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: Colors.darkBlue,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleOpenGMAP(e, order)}
                >
                  <span className="tooltiptext-custom">Go to GMAP</span>
                </i>
                <i
                  className="fa fa-search animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: Colors.darkBlue,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleOpenGSEARCH(e, order)}
                >
                  <span className="tooltiptext-custom">Search full name</span>
                </i>

                <i
                  className="fa fa-edit animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: Colors.yellow,
                    cursor: "pointer",
                  }}
                  onClick={(e) =>
                    handleEditOrderAndItems(
                      e,
                      order._id,
                      order.shipping?.currency || order.currency
                    )
                  }
                >
                  <span className="tooltiptext-custom">Edit order</span>
                </i>
                <i
                  className="fa fa-address-card animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: Colors.yellow,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleSeeInCrm(e, order._id)}
                >
                  <span className="tooltiptext-custom">Go CRM Order</span>
                </i>
                <i
                  className="fa  fa-address-book animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: Colors.yellow,
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleSeeInCrmContact(e, order.contact)}
                >
                  <span className="tooltiptext-custom">Go CRM contact</span>
                </i>
                <i
                  className="fa fa-check animation-scale tooltip-custom"
                  style={{
                    fontSize: "20px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleVerify(e, order)}
                >
                  <span className="tooltiptext-custom">Verify address</span>
                </i>
              </div>
            </div>
          </td>
        </tr>
      )),
    [data, selectedParcels]
  );

  const getGroupedCouriersString = (ordersData) => {
    const groupedCouriers = ordersData.reduce((acc, orderData) => {
      if (!acc[orderData.shipping.curier_lms_id]) {
        acc[orderData.shipping.curier_lms_id] = 0;
      }

      acc[orderData.shipping.curier_lms_id]++;

      return acc;
    }, {});

    return Object.entries(groupedCouriers)
      .map(([courierId, amount]) => `${getCourierNameById(courierId)} - ${amount}`)
      .join(", ");
  };

  const getCourierNameById = (courierId) => {
    return config
      .map((market) => market.products)
      .flat()
      .map((product) => product.posts)
      .flat()
      .find((post) => post._id === courierId)?.curier_name;
  }

  return (
    <Wrapper>
      {(hasUnfilledRequest(getOrdersToSend) ||
        hasUnfilledRequest(getMarketsConfig) ||
        hasUnfilledRequest(verifyOrder) ||
        hasUnfilledRequest(getOrdersForMarket) ||
        hasUnfilledRequest(getBooksFromCzechLogistic) ||
        hasUnfilledRequest(sendOrders) ||
        isLoadingParcels) && <Loading />}
      {marketProducts?.map((marketProduct) => {
        return (
          <Subtitle key={marketProduct._id}>
            {marketProduct._product[0].name} - {marketProduct.total};{" "}
            <CouriersInfo>
              Couriers: {getGroupedCouriersString(marketProduct.data)}
            </CouriersInfo>
          </Subtitle>
        );
      })}
      <Flex>
        <SelectInput
          name="Country"
          options={config.map((info) => info.country)}
          selected={selectedCountry}
          setSelected={(selected) => handleSelectCountry(selected)}
          color={Colors.darkBlue}
        />
        <SelectInput
          name="Product"
          selected={selectedProduct}
          options={productOptions}
          setSelected={(selected) => handleSelectProduct(selected)}
          color={Colors.darkBlue}
        />
        <SelectInput
          name="Courier"
          selected={selectedPost}
          options={postOptions}
          setSelected={setSelectedPost}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={dateRef}
          name="Shipping date"
          type="date"
          value={defaultShippingDate}
          color={Colors.darkBlue}
        />
        <button
          className="btn btn-warning"
          style={{ width: "80px", height: "40px", marginLeft: "80px" }}
          onClick={(e) => handleSearch(e)}
        >
          Search
        </button>
      </Flex>
      {data && data[0] && (
        <OrdersWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-warning"
              onClick={(e) => handleMarkAllAsChecked(e)}
            >
              Mark all
            </button>
            <button
              className="btn btn-secondary"
              onClick={(e) => handleMarkAllAsUnChecked(e)}
            >
              Unmark all{" "}
            </button>
            <SelectInput
              name="Book number"
              selected={selectedBook}
              options={bookNumbers}
              setSelected={setSelectedBook}
              width={140}
              selectWidth={240}
            />
            <button
              className="btn btn-primary"
              style={{ marginLeft: "100px", fontWeight: "bold" }}
              onClick={(e) => handleSendParcels(e)}
              disabled={!!hasUnfilledRequest(sendOrders)}
            >
              Send parcels
            </button>
            <h5 style={{ marginLeft: "20px" }}>
              Found: <strong> {data.length}</strong>
            </h5>
          </div>
          <div style={{ overflowX: "scroll" }}></div>
        </OrdersWrapper>
      )}
      {data?.length > 0 && (
        <Table
          className="styled-table font-big"
          headers={headers}
          raws={raws}
        />
      )}
      {editShow && (
        <Edit
          data={editData}
          setEditShow={setEditShow}
          currierOptions={postOptions}
        />
      )}
      {sentResultShow && (
        <SentView data={sentResultData} setSentResultShow={setSentResultShow} />
      )}
    </Wrapper>
  );
};

export default withRequestProvider(Orders);
