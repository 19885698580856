import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getJobs, startJobManual } from "../API/repositories/job";
import { Colors } from "../common/colors/colors";
import Loading from "../common/components/Loading";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { useRequestsContext } from "../common/hooks/requestHook";
import CustomLine from "../common/charts/Line";
import { COLORS_ARRAY } from "../common/colors";
import Input from "../common/components/Input";

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 60px);
  margin: 0 auto;
`;

const JobsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
  border-radius: 16px;
  padding: 16px;
`;

const ChartWrapper = styled.div`
  display: flex;
  width: 70vw;
  max-width: 1200px;
  height: 50vh;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const STATUSES = ["delivered_no_payment", "transport", "return", "awizo"];
const DAYS = {
  A: 3,
  B: 7,
  C: 30,
};
const PER_DAY = 24 * 2;

const Jobs = () => {
  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();
  const [color, setColor] = useState(null);
  const [chartData, setChartData] = useState();
  const [days, setDays] = useState(DAYS.A);
  const [jobsData, setJobsData] = useState();

  const handleStartJob = async (e) => {
    e.preventDefault();
    const response = await makeRequest(startJobManual.bind(null));
    let chosenColor = Colors.red;

    if (response.data) {
      chosenColor = Colors.green;
    }

    setColor(() => chosenColor);

    setTimeout(() => {
      setColor(() => null);
    }, [3000]);
  };

  const groupByDate = (data) =>
    data.reduce((acc, data) => {
      if (!acc[data.created_at]) {
        acc[data.created_at] = [];
      }

      acc[data.created_at].push(data);

      return acc;
    }, {});

  const handleGetJobs = async () => {
    const response = await makeRequest(getJobs.bind(null));

    if (!response?.data) {
      return;
    }

    const sortedData = sortData(response.data);

    setJobsData(() => sortedData);

    handleSetChartData(sortedData);
  };

  const handleSetChartData = (data) => {
    const { labels, datasets } = createChartData(
      data.slice(-1 * (days * PER_DAY))
    );

    setChartData(() => ({
      labels,
      datasets,
    }));
  };

  const createChartData = (data) => {
    const groupedByDate = groupByDate(data);
    const labels = Object.keys(groupedByDate).map((key) =>
      moment(key).format("DD.MM HH:mm")
    );

    const groupedByStatuses = groupByStatuses(data);
    const datasets = createDatasets(groupedByStatuses);

    return { labels, datasets };
  };

  const sortData = (data) =>
    data.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));

  const groupByStatuses = (data) =>
    data.reduce((acc, el) => {
      STATUSES.forEach((status) => {
        const statusToCrm = el.to_crm.find((item) => item.status === status);

        const amount = statusToCrm?.ids.length || 0;

        if (!acc[status]) {
          acc[status] = [];
        }

        acc[status].push(amount);
      });

      return acc;
    }, {});

  const createDatasets = (groupedByStatuses) =>
    STATUSES.map((value, i) => ({
      label: value,
      yAxisID: "y",
      data: groupedByStatuses[value],
      borderColor: COLORS_ARRAY[i],
      backgroundColor: COLORS_ARRAY[i],
    }));

  const handleRadioButtonChange = (e) => setDays(e.target.value);

  useEffect(() => {
    handleGetJobs();
  }, [color]);

  useEffect(() => {
    if (jobsData) {
      handleSetChartData(jobsData);
    }
  }, [days]);

  return (
    <Wrapper>
      {(hasUnfilledRequest(startJobManual) || hasUnfilledRequest(getJobs)) && (
        <Loading />
      )}
      <button
        className="btn btn-warning"
        onClick={(e) => handleStartJob(e)}
        style={{ backgroundColor: color, border: color }}
      >
        Run sync parcels from czechlogistic
      </button>
      {!!chartData && (
        <JobsChartContainer>
          <div style={{ display: "flex" }}>
            <Input
              onChange={handleRadioButtonChange}
              id={`jobs_${DAYS.A}`}
              inputName="jobs"
              gap={10}
              width={80}
              inputWidth={20}
              value={DAYS.A}
              name={`${DAYS.A} days`}
              color={Colors.darkBlue}
              type="radio"
              defaultChecked
            />
            <Input
              onChange={handleRadioButtonChange}
              id={`jobs_${DAYS.B}`}
              inputName="jobs"
              gap={10}
              width={80}
              inputWidth={20}
              value={DAYS.B}
              name={`${DAYS.B} days`}
              color={Colors.darkBlue}
              type="radio"
            />
            <Input
              onChange={handleRadioButtonChange}
              id={`jobs_${DAYS.C}`}
              inputName="jobs"
              gap={10}
              width={80}
              inputWidth={20}
              value={DAYS.C}
              name={`${DAYS.C} days`}
              color={Colors.darkBlue}
              type="radio"
            />
          </div>
          <ChartWrapper>
            <CustomLine data={chartData} />
          </ChartWrapper>
        </JobsChartContainer>
      )}
    </Wrapper>
  );
};

export default withRequestProvider(Jobs);
