import React, { useRef } from "react";
import styled from "styled-components";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import PopUp from "../../common/components/PopUp";
import { useRequestsContext } from "../../common/hooks/requestHook";
import {
  createCurrency,
  updateCurrency,
} from "../../API/repositories/currency";
import { Colors } from "../../common/colors";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

const FLexRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 0 -30px;
`;

export const CurrencyForm = ({ loadData, setShowCurrencyForm, currency }) => {
  const nameRef = useRef();
  const shortRef = useRef();

  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object();

    payload.name = nameRef.current.value;
    payload.short = shortRef.current.value;
    payload._id = currency?._id;

    const response = currency
      ? await makeRequest(updateCurrency.bind(null, currency._id, payload))
      : await makeRequest(createCurrency.bind(null, payload));

    if (response?.data) {
      setShowCurrencyForm(() => false);
      loadData();
    }
  };

  return (
    <>
      {hasUnfilledRequest(updateCurrency, createCurrency) && <Loading />}
      <PopUp setShow={setShowCurrencyForm}>
        <Title>{currency ? "Edit currency" : "Create currency"}</Title>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Input
            name="Name"
            value={currency?.name}
            inputRef={nameRef}
            required
            inputWidth="400"
            color={Colors.darkBlue}
          />
          <Input
            inputRef={shortRef}
            value={currency?.short}
            name="Short"
            required
            inputWidth="400"
            color={Colors.darkBlue}
          />
          <FLexRight>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </FLexRight>
        </form>
      </PopUp>
    </>
  );
};
