import React from "react";
import Navbar from "../common/components/Navbar";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 100vw;
  height: 100vh;
`;

const AdminView = ({ children }) => {
  return (
    <Wrapper>
      <Navbar />
      {children}
    </Wrapper>
  );
};

export default AdminView;
