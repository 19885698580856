import React, { createContext, useState } from "react";

export const RequestsContext = createContext();

export const RequestsProvider = ({ children }) => {
  const [requests, setRequests] = useState([]);

  const addRequest = (request) => {
    setRequests((prev) => [...prev, request]);
  };

  const removeRequest = (request) => {
    setRequests((prev) => prev.filter((req) => req !== request));
  };

  const hasUnfilledRequest = (requestFn) => {
    return requests.includes(requestFn.name);
  };

  const makeRequest = async (requestFn) => {
    const requestName =
      requestFn.name.split(" ").length > 1
        ? requestFn.name.split(" ")[1]
        : requestFn.name;
    addRequest(requestName);
    const res = await requestFn();
    removeRequest(requestName);
    return res;
  };

  return (
    <RequestsContext.Provider
      value={{
        hasUnfilledRequest,
        makeRequest,
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};
