import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getProducts } from "../API/repositories/product";
import { getPosts } from "../API/repositories/post";
import Loading from "../common/components/Loading";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { useRequestsContext } from "../common/hooks/requestHook";
import EditIcon from "../common/components/EditIcon";
import { ProductForm } from "../components/products/ProductForm";
import { getMatched, makeVisible } from "../common/functions/settingsHelpers";
import Table from "../common/components/Table";

const Wrapper = styled.div`
  padding: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const Products = () => {
  const [editProductData, setEditProductData] = useState();
  const [showEditProduct, setShowEditProduct] = useState();
  const [products, setProducts] = useState();
  const [showCreateProduct, setShowCreateProduct] = useState();
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadData = async () => {
    const productsResponse = await makeRequest(getProducts.bind());

    if (productsResponse.data) {
      setProducts(() => productsResponse.data);
    }

    const postsResponse = await makeRequest(getPosts.bind());

    if (postsResponse.data) {
      setPosts(() => postsResponse.data);
    }
  };

  const handleEditProduct = (e, product) => {
    e.preventDefault();

    setEditProductData(() => product);
    setShowEditProduct(() => true);
  };

  useEffect(() => {
    loadData();
  }, []);

  const headers = [
    "No",
    "Name",
    "Short",
    "Variants",
    "Couriers",
    "Min price",
    "Created at",
    "Options",
  ];
  const raws = useMemo(
    () =>
      products?.map((product, i) => (
        <tr key={product._id}>
          <td>{i + 1}</td>
          <td>{product.name}</td>
          <td>{product.short}</td>
          <td
            style={{
              maxWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {makeVisible(product.variants)}
          </td>
          <td
            style={{
              maxWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {makeVisible(
              getMatched([...product.default_posts, ...product.posts], posts)
            )}
          </td>
          <td>{product.min_price}</td>
          <td>{moment(product.created_at).format("YYYY-MM-DD HH:mm")}</td>
          <td>
            <EditIcon handleEdit={(e) => handleEditProduct(e, product)} />
          </td>
        </tr>
      )),
    [posts, products]
  );

  return (
    <Wrapper>
      {hasUnfilledRequest(getProducts) && <Loading />}
      <Flex>
        <button
          className="btn btn-warning"
          onClick={() => navigate("/settings")}
        >
          Go back
        </button>

        <button
          className="btn btn-warning"
          onClick={() => setShowCreateProduct(true)}
        >
          Create Product
        </button>
      </Flex>
      {showCreateProduct && (
        <ProductForm
          loadData={loadData}
          posts={posts}
          setShowProductForm={setShowCreateProduct}
        />
      )}
      {showEditProduct && (
        <ProductForm
          loadData={loadData}
          setShowProductForm={setShowEditProduct}
          product={editProductData}
          posts={posts}
        />
      )}
      <Flex>
        {products && (
          <Table className="styled-table" raws={raws} headers={headers} />
        )}
      </Flex>
    </Wrapper>
  );
};

export default withRequestProvider(Products);
