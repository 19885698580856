import React, { useRef } from "react";
import styled from "styled-components";
import Input from "../../common/components/Input";
import Loading from "../../common/components/Loading";
import PopUp from "../../common/components/PopUp";
import { useRequestsContext } from "../../common/hooks/requestHook";
import { createFlag, updateFlag } from "../../API/repositories/flags";
import { Colors } from "../../common/colors";
import { getBase64 } from "../../common/functions/getBase64";

const Title = styled.h4`
  color: ${Colors.darkBlue};
  margin-bottom: 20px;
`;

const FLexRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 0 -30px;
`;

export const FlagForm = ({ loadData, setShowFlagForm, flag }) => {
  const nameRef = useRef();
  const shortRef = useRef();
  const imageRef = useRef();

  const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = Object();

    const base64 = imageRef.current?.files[0]
      ? await getBase64(imageRef.current?.files[0])
      : flag?.base64;

    if (!base64) {
      return;
    }

    payload.name = nameRef.current.value;
    payload.short = shortRef.current.value;
    payload.base64 = base64;
    payload._id = flag?._id;

    const response = flag
      ? await makeRequest(updateFlag.bind(null, flag._id, payload))
      : await makeRequest(createFlag.bind(null, payload));

    if (response?.data) {
      setShowFlagForm(() => false);
      loadData();
    }
  };

  return (
    <>
      {hasUnfilledRequest(updateFlag, createFlag) && <Loading />}
      <PopUp setShow={setShowFlagForm}>
        <Title>{flag ? "Edit flag" : "Create flag"}</Title>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Input
            name="Name"
            value={flag?.name}
            inputRef={nameRef}
            required
            inputWidth="400"
            color={Colors.darkBlue}
          />
          <Input
            inputRef={shortRef}
            value={flag?.short}
            name="Short"
            required
            inputWidth="400"
            color={Colors.darkBlue}
          />
          <Input
            inputRef={imageRef}
            type="file"
            name="Image"
            inputWidth="400"
            color={Colors.darkBlue}
          />
          <FLexRight>
            <button type="submit" className="btn btn-warning">
              Save
            </button>
          </FLexRight>
        </form>
      </PopUp>
    </>
  );
};
