import { RequestsProvider } from "../contexts/requestsContext";

const withRequestProvider = (Component) => (props) =>
  (
    <RequestsProvider>
      <Component {...props} />
    </RequestsProvider>
  );

export default withRequestProvider;
