import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;

export const createProduct = (product) => {
  return errorHandler(async () => {
    const data = await api.post("/product", product);
    return { data: data.data };
  });
};

export const getProducts = () => {
  return errorHandler(async () => {
    const data = await api.get("/product");
    return { data: data.data };
  });
};

export const getOneProduct = (_id) => {
  return errorHandler(async () => {
    const data = await api.get(`product/${_id}`);
    return { data: data.data };
  });
};

export const updateProduct = (_id, product) => {
  return errorHandler(async () => {
    const data = await api.patch(`product/${_id}`, product);
    return { data: data.data };
  });
};

export const deleteProduct = (_id) => {
  return errorHandler(async () => {
    const data = await api.delete(`product/${_id}`);
    return { data: data.data };
  });
};
