import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getPosts } from "../API/repositories/post";
import EditIcon from "../common/components/EditIcon";
import Loading from "../common/components/Loading";
import withRequestProvider from "../common/hocs/withRequestProvider";
import { CourierForm } from "../components/posts/CourierForm";
import { useRequestsContext } from "../common/hooks/requestHook";
import Table from "../common/components/Table";

const Wrapper = styled.div`
  padding: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const Couriers = () => {
  const [editPostData, setEditPostData] = useState();
  const [showEditPost, setShowEditPost] = useState();
  const [posts, setPosts] = useState();
  const [showCreatePost, setShowCreatePost] = useState();
  const navigate = useNavigate();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const loadData = async () => {
    const response = await makeRequest(getPosts.bind());
    if (response.data) {
      setPosts(() => response.data);
    }
  };

  const handleEditPost = (e, post) => {
    e.preventDefault();

    setEditPostData(() => post);
    setShowEditPost(() => true);
  };

  useEffect(() => {
    loadData();
  }, []);

  const headers = [
    "No",
    "Name",
    "Short",
    "Courier name",
    "Created at",
    "Options",
  ];
  const raws = useMemo(
    () =>
      posts?.map((post, i) => (
        <tr key={post._id}>
          <td>{i + 1}</td>
          <td>{post.name}</td>
          <td>{post.short}</td>
          <td>{post.curier_name}</td>
          <td>{moment(post.created_at).format("YYYY-MM-DD HH:mm")}</td>
          <td>
            <EditIcon handleEdit={(e) => handleEditPost(e, post)} />
          </td>
        </tr>
      )),
    [posts]
  );

  return (
    <Wrapper>
      {hasUnfilledRequest(getPosts) && <Loading />}
      <Flex>
        <button
          className="btn btn-warning"
          onClick={() => navigate("/settings")}
        >
          Go back
        </button>

        <button
          className="btn btn-warning"
          onClick={() => setShowCreatePost(true)}
        >
          Create courier
        </button>
      </Flex>
      {showCreatePost && (
        <CourierForm loadData={loadData} setShowPostForm={setShowCreatePost} />
      )}
      {showEditPost && (
        <CourierForm
          loadData={loadData}
          setShowPostForm={setShowEditPost}
          post={editPostData}
        />
      )}
      <Flex>
        {posts && (
          <Table className="styled-table" raws={raws} headers={headers} />
        )}
      </Flex>
    </Wrapper>
  );
};

export default withRequestProvider(Couriers);
