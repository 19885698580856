import React, { useMemo } from "react";
import { Colors } from "../../common/colors";
import PopUp from "../../common/components/PopUp";
import Table from "../../common/components/Table";

const SentView = ({ data, setSentResultShow }) => {
  const getOrderItems = (order) => {
    return order.shipping?.order_items?.length
      ? order.shipping?.order_items
      : order.order_items;
  };

  const goodHeaders = [
    "Label",
    "City",
    "Postal code",
    "Street",
    "Full name",
    "Phone",
    "E-mail",
    "COD",
  ];
  const goodRaws = useMemo(
    () =>
      data?.sentParcels?.map((parcel) => (
        <tr key={parcel.recipientTel}>
          <td>{parcel.reference}</td>
          <td>{parcel.recipientCity}</td>
          <td>{parcel.recipientPostCode}</td>
          <td>{parcel.recipientStreet}</td>
          <td>{parcel.recipientName}</td>
          <td>{parcel.recipientTel}</td>
          <td>{parcel.recipientEmail}</td>
          <td>{parcel.cod}</td>
        </tr>
      )),
    [data?.sentParcels]
  );

  const wrongHeaders = [
    "Country",
    "City",
    "Postal code",
    "Street",
    "Full name",
    "Phone",
    "E-mail",
    "Value",
    "Variants",
    "Error",
  ];
  const wrongRaws = useMemo(
    () =>
      data?.unSentParcels?.map((parcel) => (
        <tr key={parcel.recipientCity}>
          <td>{parcel.country}</td>
          <td>{parcel.city}</td>
          <td>{parcel.postal_code}</td>
          <td>{parcel.street}</td>
          <td>{parcel.full_name}</td>
          <td>{parcel.phone?.courier || parcel.phone?.default}</td>
          <td>{parcel.email}</td>
          <td>
            {getOrderItems(parcel).reduce((last, { price }) => last + price, 0)}
          </td>

          <td>
            {getOrderItems(parcel)
              .map((item) => item.product_variant)
              .join(", ")}
          </td>
          <td style={{ color: "red", fontWeight: "bold" }}>
            {parcel.excepton}
          </td>
        </tr>
      )),
    [data?.unSentParcels]
  );

  return (
    <PopUp setShow={setSentResultShow}>
      {data.sentParcels?.length > 0 && (
        <div style={{ overflowY: "scroll" }}>
          <h4 style={{ color: Colors.green, fontWeight: "bold" }}>Sent</h4>
          <Table
            className="styled-table good-table"
            raws={goodRaws}
            headers={goodHeaders}
          />
        </div>
      )}
      {data.unSentParcels?.length > 0 && (
        <div style={{ overflowY: "scroll" }}>
          <h4 style={{ color: Colors.red, fontWeight: "bold" }}>To check</h4>
          <Table
            className="styled-table wrong-table"
            raws={wrongRaws}
            headers={wrongHeaders}
          />
        </div>
      )}
    </PopUp>
  );
};

export default SentView;
