export const ALL_ACCESSES = [
  "CONTACT",
  "ORDER",
  "PAYMENT_REPORT",
  "EFFECTIVITY_REPORT",
  "CRM_SETTIGNS",
  "CONSULTANT_COMMON",
  "CONSULTANT_MANAGER",
];

const ACCESSES = {
  ADMIN: ALL_ACCESSES,
  MANAGER: ["CONSULTANT_COMMON", "CONSULTANT_MANAGER"],
  CONSULTANT: ["CONSULTANT_COMMON"],
  USER: [],
  SUPPORT: ["CONTACT", "ORDER"],
  PRODUCT_MANAGER: ["CONTACT", "ORDER", "PAYMENT_REPORT", "EFFECTIVITY_REPORT"],
};

class UserManager {
  setUser(user) {
    let payload = JSON.stringify(user);
    localStorage.setItem("user", payload);
  }

  getUser() {
    return localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  getAllRoles() {
    return Object.keys(ACCESSES);
  }

  getAllAcesses() {
    return ALL_ACCESSES;
  }

  getRolesAccesses(role) {
    return ACCESSES[role];
  }

  checkAccess(access) {
    return this.getUser()?.access?.includes(access);
  }
}

export default new UserManager();
