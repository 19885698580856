import { errorHandler } from "../common";
import Api from "../api";
const { axios: api } = Api;

export const resetOrders = (waybills) =>
  errorHandler(async () => {
    const data = await api.post("/crm/reset_orders_by_waybills", { waybills });
    return { data: data.data };
  });

export const getOrdersDataByWaybills = (waybills) =>
  errorHandler(async () => {
    const data = await api.post("/crm/get_orders_by_waybills", { waybills });

    return { data: data.data };
  });
