import { errorHandler } from "../common";
import Api from "../api";
const { tokenManager, axios: api } = Api;

export const startJobManual = () =>
  errorHandler(async () => {
    const data = await api.post("/cron/job", {});
    return { data: data.data };
  });

export const getJobs = () =>
  errorHandler(async () => {
    const data = await api.get("/cron/jobs");
    return { data: data.data };
  });

export const getJobStatistic = (_id) =>
  errorHandler(async () => {
    const data = await api.get(`/cron/job/${_id}`);
    return { data: data.data };
  });
