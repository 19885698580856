import React from "react";

const Table = ({ headers, raws, className, style = {} }) => {
  return (
    <table className={className} style={style}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {raws.map(raw => raw)}
      </tbody>
    </table> 
  );
};

export default Table;
